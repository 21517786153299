import React from "react";
import { Select } from "../../../helpers";
import { useSelector } from "react-redux";
import { createOptions } from "../../../../utils";
import { getContactOptions } from "../../../../selectors/dropdowns";
import { useReportContext } from "../../report_context";
import { FilterMainComponentProps } from "../Filter";

const ClientContactSelect = ({
  values,
  onChange,
  placeholder = "All",
}: FilterMainComponentProps) => {
  const { query } = useReportContext();
  const selectedClient = query["client_id"];

  const clientContactOptions = createOptions(
    useSelector((s) => getContactOptions(s, { parent_id: selectedClient })),
    "key",
    "value",
    { addAll: true },
  );

  return (
    <Select
      value={values}
      // @ts-ignore
      onChange={(e: { value: string; label: string }) =>
        onChange(e?.value || null)
      }
      isClearable={values !== ""}
      // @ts-ignore
      options={clientContactOptions}
      placeholder={placeholder}
    />
  );
};

export default ClientContactSelect;
